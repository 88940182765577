<script setup>
import { useRoute } from 'vue-router'
import { useCookie } from 'nuxt/app'
const route = useRoute()
// Update UTM cookies with parameters if they exist in the URL
for (let param in route.query) {
  let options = {
    maxAge: 864000 // 10 days to expire
  }
  let cookieVal = useCookie(param, options)
  cookieVal.value = route.query[param]
}
</script>
<template>  
  <main>
    <NuxtPage />
  </main>
</template>